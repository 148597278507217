var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column justify-space-around align-center flex-md-row",class:_vm.$vuetify.breakpoint.mdAndUp
        ? _vm.$style.sectionHeight
        : _vm.$style.sectionHeightMobile},[_c('div',{staticClass:"ma-4",class:_vm.$vuetify.breakpoint.mdAndUp ? _vm.$style.widthFirstDesktop : ''},[_c('div',{staticClass:"mb-8"},[_c('v-chip',{staticClass:"mr-3",class:_vm.$style.chip,attrs:{"color":_vm.$style.colorSecondary}},[_vm._v("Online")]),_c('v-chip',{class:_vm.$style.chip,attrs:{"color":_vm.$style.colorSecondaryBis}},[_vm._v("Matchmaking service ")])],1),_c('h1',{staticClass:"mb-2",class:_vm.$vuetify.breakpoint.mdAndUp
            ? _vm.$style.mainTitle
            : _vm.$style.mainTitleMobile},[_vm._v(" Find the right players for you ")]),_c('p',{class:_vm.$vuetify.breakpoint.mdAndUp
            ? _vm.$style.description
            : _vm.$style.descriptionMobile},[_vm._v(" A social platform that helps you put together the perfect team ")])]),_c('v-img',{attrs:{"src":require('../assets/home/image1.jpg'),"max-height":_vm.$vuetify.breakpoint.mdAndUp ? 500 : 300,"max-width":_vm.$vuetify.breakpoint.mdAndUp ? 500 : 300}})],1),_c('div',{staticClass:"\n      d-flex\n      flex-column\n      justify-space-around\n      align-center\n      flex-md-row-reverse\n    ",class:_vm.$vuetify.breakpoint.mdAndUp
        ? _vm.$style.sectionHeight
        : _vm.$style.sectionHeightMobile},[_c('div',{staticClass:"ma-4",class:_vm.$vuetify.breakpoint.mdAndUp ? _vm.$style.widthSecondDesktop : ''},[_c('div',{staticClass:"mb-8"},[_c('v-chip',{staticClass:"mr-3",class:_vm.$style.chip,attrs:{"color":_vm.$style.colorSecondary}},[_vm._v("Co-op games")]),_c('v-chip',{class:_vm.$style.chip,attrs:{"color":_vm.$style.colorSecondaryBis}},[_vm._v("Multiplayer")])],1),_c('h1',{staticClass:"mb-2",class:_vm.$vuetify.breakpoint.mdAndUp ? _vm.$style.title : _vm.$style.titleMobile},[_vm._v(" Connect with people ")]),_c('p',{class:_vm.$vuetify.breakpoint.mdAndUp
            ? _vm.$style.description
            : _vm.$style.descriptionMobile},[_vm._v(" Don't play on your own anymore! Meet people who want to play the same game as you right now ")])]),_c('v-img',{attrs:{"src":require('../assets/home/image2.jpg'),"max-height":_vm.$vuetify.breakpoint.mdAndUp ? 500 : 300,"max-width":_vm.$vuetify.breakpoint.mdAndUp ? 500 : 300}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"mx-4"},[_c('h1',{class:_vm.$vuetify.breakpoint.mdAndUp ? _vm.$style.title : _vm.$style.titleMobile},[_vm._v(" Find your team on any game ")]),_c('p',{class:_vm.$vuetify.breakpoint.mdAndUp
            ? _vm.$style.description
            : _vm.$style.descriptionMobile},[_vm._v(" Hundreds of compatible games ")])]),_c('div',{class:_vm.$style.gamesGrid},[_c('div',{staticClass:"d-flex justify-space-around px-16 py-10"},[(this.games.length == 0)?_c('v-row',_vm._l((12),function(n){return _c('v-col',{key:n,staticClass:"d-flex justify-center",attrs:{"md":"2","cols":"4"}},[_c('v-skeleton-loader',{attrs:{"aspect-ratio":3 / 4,"width":"150","height":"225","type":"image","dark":"","tile":""}})],1)}),1):_c('v-row',_vm._l((_vm.games),function(game,index){return _c('v-col',{key:index,staticClass:"d-flex justify-center",attrs:{"md":"2","cols":"4"}},[_c('v-img',{attrs:{"src":game.Cover_Url,"aspect-ratio":3 / 4,"max-width":"150"}})],1)}),1)],1)])]),_c('div',{staticClass:"d-flex justify-center pb-16",class:_vm.$vuetify.breakpoint.mdAndUp
        ? _vm.$style.lastSectionHeight
        : _vm.$style.lastSectionHeightMobile},[_c('h1',{class:_vm.$vuetify.breakpoint.mdAndUp ? _vm.$style.title : _vm.$style.titleMobile},[_vm._v(" Join us ")]),_c('h1',{staticClass:"mx-2 px-2",class:_vm.$vuetify.breakpoint.mdAndUp
          ? _vm.$style.coloredTitle
          : _vm.$style.coloredTitleMobile},[_vm._v(" now ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }